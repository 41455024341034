import React from 'react'

import '../../styles/general.css'

function LandingHero(){
    return(
        <div className="landing-page">
            <h1
                className="txt-is-white"
            >
                iandemed.dev
            </h1>
        </div>
    )

}

export default LandingHero